// assets
import { BarChartOutlined } from '@ant-design/icons';

// icons
const icons = {
  BarChartOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const analysis = {
  id: 'analysis',
  title: 'Analysis',
  type: 'group',
  children: [
    {
      id: 'oosbydate',
      title: 'OOS Rate By Date',
      type: 'item',
      url: '/oos/date',
      icon: icons.BarChartOutlined,
      breadcrumbs: false
    },
    {
      id: 'oosbysubdept',
      title: 'OOS Rate By SubDept',
      type: 'item',
      url: '/oos/subdept',
      icon: icons.BarChartOutlined,
      breadcrumbs: false
    },
    {
      id: 'accuracy',
      title: 'BOH Accuracy',
      type: 'item',
      url: '/accuracy',
      icon: icons.BarChartOutlined,
      breadcrumbs: false
    },
    {
      id: 'inventoryflow',
      title: 'Inventory Flow',
      type: 'item',
      url: '/inventoryflow',
      icon: icons.BarChartOutlined,
      breadcrumbs: false
    }
  ]
};

export default analysis;
