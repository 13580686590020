// project import
import pages from './pages';
import dashboard from './dashboard';
import analysis from './analysis';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, analysis, pages]
};

export default menuItems;
